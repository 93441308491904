@media only screen and (min-width:1540px){
   .gpp_logo{
    width: 45%;
   }
   .team_flex img{
    height: 500px;
   }
   .contact_content{
    margin-right: -100px;
  }
  .about_summary img{
    margin-left: 270px;
  }
}