

/* styling for mobile devices */

@media only screen and (max-width:500px){

  /* storing commonly used colors */

    :root{
      --website_main_theme: rgb(121, 50, 56);
      --website_sec_theme: #9C4C56;
    }

    /* fixing overlays on all the pages */

    .about_overlay h1, 
    .news_overlay h1, 
    .partners_overlay h1, 
    .strategy_overlay h1, 
    .team_overlay h1, 
    .portfolio_overlay h1{
      font-size: 34px;
      margin-left: 10px;
      margin-right: 10px;
    }

    /* styling the home page */

    /* hamburger symbol */

    .hamburger{
      margin-right: 10px;
    }
    .gpp_logo{
      width: 85%;
      /* width: 300px; */
      margin-left: 0px;
      margin-right: 80px;
    }

    /* navbar for mobile devices */

    .mobile_navbar{
      text-align: center;
      margin-top: 150px;
      margin-left: 30px;
      margin-right: 30px;
    }
    .mobile_navbar ul{
      list-style: none;
    }
    .mobile_navbar ul li {
      margin-bottom: 25px;
      text-align: center;
    }
    .mobile_menu_item{
      background-color: var(--website_main_theme);
      color:#fff;
      text-decoration: none;
      display: block;
      padding-top: 10px;
      padding-bottom:10px;
      font-size: 18px;
    }
    .mobile_menu_item:hover{
      opacity: 0.8;
    }

    /* the link which user is on currently */

    .active-link{
      text-decoration: none;
      font-size: 20px;
      display: block;
      color:#333;
      font-weight: bold;
      background-color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s ease-in-out;
      font-weight: bold;
    }
    .active-link:hover{
      transform: scale(1.1);
    }
    /* This class is not overiding the styling done earlier (line 31)
      Instead, this is also styling the hamburger symbol but this class is present in 
      the original styling of this npm package that is done by their developers and I have just changed it here
      This class was originally present in the styling by default.  
    */

    .hamburger{
      background-color: var(--website_main_theme);
    }

    .home_content h1{
      margin-left: 30px;
      /* font-size: 34px; */
      font-size: 28px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
    .home_content .other_description{
      margin-left: 30px;
      margin-bottom: 30px;
      margin-top: 5px;
      margin-right: 10px;
      line-height: 1.6;
    }
    .home_content .comp_name{
      margin-left: 30px;
      margin-bottom: 25px;
      font-size: 22px;
    }
    .about_link{
      font-size: 16px;
      margin-left: 30px;
      padding-left: 30px;
      width: 170px;
    }
    .home_section::before{
      height: 115%;
    }
    video{
      height: 115%;
    }

    .about_summary{
      padding: 0px;
    }

    .about_summary_grid{
      display: block;
    }
    .about_summary h1{
      margin-top: 90px;
      font-size: 28px;
    }
    .about_summary_content{
      display: block;
    }
    .about_summary p{
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
    }
    .about_summary img{
      width: 70%; 
      height: 10%;
      display: block;
      margin: auto;
      margin-top: 170px;
    }
    .about_underline, 
    .news_underline
    {
      width: 50%;
    }
    .portfolio_summary h1{
      margin-top: 70px;
      font-size: 28px;
      padding-bottom: 30px;
      padding-top: 80px;
    }
    .portfolio_note{
      margin-bottom: 50px;
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
    }
    .portfolio_summary_content{
      padding-top: 20px;
      padding-bottom: 30px; 
      padding-left: 20px; 
      padding-right: 20px; 
      display: block; 
      width: 90%;
    }
    .portfolio_summary_content img{
      width: 100%;
    }
    .portfolio_summary_content h4{
      text-align: center;
      font-size: 26px;
    }
    .portfolio_summary_content p{
      font-size: 18px;
    }
    .portfolio_link_holder{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .portfolio_link{
      margin: auto;
    }
    .portfolio_underline{
      width: 25%;
    }
    .news_summary h1{
      margin-left:5px;
      font-size: 28px;
    }
    .news_summary_grid{
      margin-top: 20px;
      display: block;
      padding: 15px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .news_summary_grid img{
      width: 130px;
      height: 100%;
      object-fit: cover;
    }
    .news_summary_content{
      width: 90%;
    }
    .news_post_flex{
      margin-bottom: 40px;
    }
    .news_summary_content a{
      margin:10px;
      font-size: 18px;
    }
    .news_summary_content p{
      margin-top: 0px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .news_date{
      top:7px;
      left: 7px;
    }
    .news_link{
      font-size: 18px;
    }

    /* about page styling */

    .about_page_flex{
      display: block;
    }
      
    .flip-card {
      width: 90%;
      height:500px;
    }
    .flip-card-back p{
      font-size: 18px;
      padding-left: 20px;
    }
    .card_1{
      margin-bottom: 50px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .card_2{
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 50px;
    }
    
    /* strategy page styling */

    .strategy_heading{
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
      margin-top: 60px;
      margin-bottom: 0px;
    }
    .strategy_main_img, .strategy_sec_img{
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -40px;
    }
    .strategy_sec_img{
      padding-bottom: 100px;
    }
    .strategy_main_img img{
      width: 90%;
      height: 500px;
      object-fit: contain;
    }
    .strategy_sec_img img{
      width: 90%;
      object-fit: contain;
    }
    .strategy_sec_heading h1{
      line-height: 1.5;
      padding-bottom: 30px;
      margin-top: 0px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .strategy_sec_heading p{
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
      margin-bottom: 110px;
    }
    .strategy_underline{
      width: 70%;
    }

    /* portfolio page styling */

    .portfolio_heading{
      margin-left: 30px;
      margin-right: 30px;
      font-size:18px;
    }
    .portfolio_comp{
      display: block;
      margin-left: 0px;
      margin-right: 0px;
    }
    .comp_image{      
      width: 40vw;
    }
    .comp_image img{
      display: block;
      margin: auto;
      width: 160%;
    }
    .comp_content{
      padding:30px;
      width: 100%;
      font-size: 18px;
    }
    .read_more_flex{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .comp_content .read_more{
      font-size: 18px;
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
      padding: 10px 20px;
    }

    /* team page styling */

    .team_heading{
      line-height: 1.5;
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
    }
    .team_flex{
      margin-top: 100px;
      margin-bottom: 150px;
      margin-left: 30px;
      margin-right: 30px;
      display: block;
    }
    .team_flex img{
      width: 250px;
      height: 300px;
      display: block;
      margin: auto;
    }
    .team_member_content{
      margin-bottom: 50px;
    }
    .team_flex .name{
      margin-top: 16px;
      font-size: 22px;
      text-align: center;
    }
    .team_flex .position{
      text-align: center;
      line-height: 1.5;
    }
    .team_flex .about_people{
      text-align: center;
    }
    .bio{
      padding-left: 30px;
      padding-right: 0px;
    }

    /* partners page styling */

    .partners_content{
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
    }
    .partners_image{
      display: block;
    }
    .partners_flex{
      display: block;
    }
    .partners_flex img{
      display: block;
      margin: auto;
      width: 80%;
    }
    .partners_flex .partners_img_2{
      display: block;
      margin: auto;
      width: 80%;
      padding-top: 50px;
    }
    .partners_img_3{
      display: flex;
      justify-content: center;
      margin-left: 40px;
      margin-right: 40px;
    }
    .partners_img_3 img{
      width: 100%;
    }

    /* news page styling */

    .news_content{
      display: block;
      padding: 20px 30px;
    }
    .news_posts{
      margin-top: 30px;
      margin-bottom: 40px;
    }

    /* hiding the scroll to top button */

    .scroll_to_top{
      display: none;
    }

    /* styling the contact page  */

    .contact_section_flex{
      display: block;
      margin-left: 20px;
      margin-right: 0px;
    }
    .contact_content{
      margin-right: 0px;
    }
    .contact_content h1{
      text-align: center;
      margin-top: -30px;
      font-size: 28px;
    }
    .contact_content p{
      text-align: center;
      line-height: 1.6;
      margin-bottom: 30px;
    }
    .contact_form .name_input{
      display: block;
    }
    .contact_form .name_input input{
      width: 100%;
    }
    .contact_form input{
      font-size: 16px;
      padding: 10px;
    }
    .contact_form .first-name{
      margin-right: 0px;
      margin-bottom: 20px;
    }
    .contact_form{
      background-color: #fff;
      padding-top: 30px;
      padding-left: 30px;
      padding-bottom: 30px;
      padding-right: 30px;
      margin-right: 10px;
    }
 

    /* styling the footer */

    .footer_section h1{
      margin-left: 0px;
    }
    .footer_flex{
      padding-top: 0px;
      display: block;
      padding-left: 0px;
    }
    .footer_flex_2{
      display: block;
    }
    .gpp_logo_img img{
      width: 90%;
      display: block;
      margin: auto;
      padding-top: 80px;
      padding-bottom: 50px;
    }
    .footer_flex h1{
      padding-bottom: 20px;
      margin-left: 0px;
      text-align: center;
    }
    .explore_links{
      margin-bottom: 30px;
    }
    .explore_links a{
      margin-bottom: 10px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .footer_short_underline{
      height: 2px;
      width: 15%;
      margin-bottom: 40px;
      text-align: center;
      margin: auto;
      margin-bottom: 40px;
    }
    .footer_links{
      margin-bottom: 10px;
      margin-left: 0px;
      text-align: center;
    }

    .footer_footer{
      padding-bottom: 100px;
    }
    .footer_footer h1{
      text-align: center;
    }
    .footer_follow_us{
      margin-left: 0px;
    }
    .social_media_links{
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }

    /* styling the social media links */

    .linkedIn_icon{
      margin-left: 0px;
    }
    .twitter_icon, .youtube_icon{
      margin-left: 20px;
    }
    .footer_contact{
      margin-right: 0px;
      margin-left: 0px;
      text-align: center;
    }
    .footer_contact p{
      margin-left: 0px;
    }
    .footer_contact_link{
      margin: auto;
    }
    .footer_pitch{
      margin-left: 0px;
      padding-right: 0px;
    }
    .pitch_heading{
      margin-top: 60px;
    }
    .footer_pitch p{
      margin-bottom: 50px;
      margin-left: 20px;
      margin-right: 10px;
      text-align: center;
    }
    .footer_contact_link{
      margin: auto;
    }
    .pitch_link_holder{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .pitch_link{
      padding: 12px 20px 12px 20px;
      margin:auto;
    }

    /* styling the pitch deck section */

    .pitch_deck_section{
      margin-bottom: 100px;
    }
    .pitch_deck_section p{
      font-size: 18px;
      margin-left: 10px;
      margin-right: 10px;
      line-height: 1.5;
    }
    .pitch_deck_section h1{
      margin-left: 10px;
      margin-right: 10px;
      font-size: 28px;
    }

    /* styling the not found page */

    .not_found h1{
      font-size: 34px;
      margin-bottom: 30px;
    }
    .not_found p{
      font-size: 18px;
      margin-bottom: 50px;
    }
    .home_link{
      font-size: 18px;
      width: 200px;
      padding: 10px 20px;
    }

    /* styling the privacy policy page */

    .privacy_policy_notice p{
      margin-left: 10px;
      margin-right: 20px;
      line-height: 1.5;
    }
    .privacy_policy_button{
      font-size: 14px;
    }
    .privacy_policy{
      margin-top: 170px;
      padding-left: 25px;
      padding-right: 20px;
      line-height: 1.6;
    }
    .privacy_policy h1{
      line-height: 1.2;
      font-size: 28px;
    }
    .privacy_policy p{
      font-size: 18px;
    }
}