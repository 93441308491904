
/* for fixing the problem of footer alignment where contact us and send us your pitch deck button didn't have the same alignment */

@media only screen and (min-width:1300px) and (max-width:1430px){
    .flip-card{
        width: 600px;
        height: 445px;
    }
    .footer_pitch p{
        margin-bottom: 30px;
    }
    
}